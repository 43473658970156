import React from 'react';
import BisBookAlt from '@meronex/icons/bi/BisBookAlt';
import '../index.css';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { SectionTab } from 'polotno/side-panel';
import FaTools from '@meronex/icons/fa/FaTools';

import {
  Button,

} from '@blueprintjs/core';

export const ToolsPanel = observer(({ store }) => {
  const [searchTerm, setSearchTerm] = useState('');

   return (
    <div>    
            
               
      <div className='templates-section'>
        
        <div>            
        <center> <h5 style={{ marginBottom: '10px', marginTop: '5px' }}>
          <FaTools/> Tools- ferramentas para extras para o seu e-book</h5>
         </center>
         </div> 

         <div className='scrollable-container-capa'>
            

        <div style={{
            fontSize: '12,0px',
            fontWeight: 'normal',
            color: 'blak',
            marginTop: '20px',
            textAlign: 'center'
            
          }}>
Use os recursos disponíveis nessa caixa de ferramentas para auxiliar na produção do seu e-book.
          </div> 

    <br/>
<center>

            <p>
           
           {/*
            <AnchorButton
            intent="success"
            href="https://editoraitacaiunas.com.br/editor-de-livros-online-itacaiunas/"
            target="_blank"
           //</div> icon={
             // <FcPlus className="bp4-icon" style={{ fontSize: '20px' }} />
            //}          
          style={{
            width: '180px',
            height: '20px',
            fontSize: '15px',
            fontWeight: 'bold',
            alignItems: 'center',
            color: 'white',
            backgroundColor: '#8c9c7b',
            borderRadius: '30px' // Adicionando a propriedade borderRadius
       
          }}
          >
            Desbloquear tudo
        </AnchorButton> */}

<Button
            text=" GERAR QR CODE"
            intent="none"   
            icon= "qrcode"        
            onClick={() => {
              store.openSidePanel('qr');
            }}
            style={{
                width: '85%',
                height: '30px',
                fontSize: '15px',
                fontWeight: 'bold',
                alignItems: 'center',
                color: 'white',
                backgroundColor: '#8c9c7b',
                borderRadius: '30px' // Adicionando a propriedade borderRadius
           
              }}
          />  
          
           </p>
         
          <p>
          <Button
            text="POSTS PARA REDES SOCIAIS"
            intent="none"
             onClick={() => {
              store.openSidePanel('miolo');    
            }}
            style={{
                width: '85%',
                height: '30px',
                fontSize: '15px',
                fontWeight: 'bold',
                alignItems: 'center',
                color: 'white',
                backgroundColor: '#8c9c7b',
                borderRadius: '30px' // Adicionando a propriedade borderRadius
           
              }}
          />  
        </p>
    
          <p>
          <Button
            text="CRIAR IMAGENS COM IA"
            intent="none"
             onClick={() => {
              store.openSidePanel('stable-diffusion');    
            }}
            style={{
                width: '85%',
                height: '30px',
                fontSize: '15px',
                fontWeight: 'bold',
                alignItems: 'center',
                color: 'white',
                backgroundColor: '#8c9c7b',
                borderRadius: '30px' // Adicionando a propriedade borderRadius
           
              }}
          />            
             </p>
              
             <p>
          <Button
            text="EDITAR IMAGENS COM IA"
            intent="none"
             onClick={() => {
              store.openSidePanel('edicao-pro');    
            }}
            style={{
                width: '85%',
                height: '30px',
                fontSize: '15px',
                fontWeight: 'bold',
                alignItems: 'center',
                color: 'white',
                backgroundColor: '#8c9c7b',
                borderRadius: '30px' // Adicionando a propriedade borderRadius
           
              }}
          />       
                       

          </p>
          
          </center>

     </div>
      </div>
    </div>
  );
});

// define the new custom section
export const ToolsSection = {
  name: 'tools',
  Tab: (props) => (
    <SectionTab name="Tools" {...props}>
      <FaTools/>
    </SectionTab>
  ),
  visibleInList: true,

  // we need observer to update component automatically on any store changes
  Panel: ToolsPanel,
  
};