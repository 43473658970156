import React from 'react';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { SidePanel } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';
import { loadFile } from './file';
// import { ThenounprojectSection } from './thenounproject-section';
import { QuotesSection } from './sections/quotes-section';
import { ShapesSection } from './sections/shapes-section';
import { StableDiffusionSection } from './sections/stable-diffusion-section';
import { MyDesignsSection } from './sections/my-designs-section';
import { QrSection } from './sections/qr-section';
import { IconsSection } from './sections/icons-sectionEdit';
import { MioloSection} from './sections/social-post';
import { CapaSection} from './sections/custom-capa';
import { PostCustom } from './sections/posts-free';
import { TutorialSection } from './sections/tutorial';
import {  CatalogacaoSection } from './sections/catalogacao';
import { IASection } from './sections/ia-criativa';
import { ToolsSection } from './sections/tools';
import { observer } from 'mobx-react-lite';
import { UploadPanel } from './upload/upload-panel';
import Topbar from './topbar/topbar';
import { DEFAULT_SECTIONS, TextSection, PhotosSection, LayersSection } from 'polotno/side-panel';
import SizeTabCustom from './sections/SizeTabCustom';
import { ComecarLivroSection } from './sections/comece-livro';
import { EdicaoProSection } from './sections/editor-pro';
import { PageControls } from './page-controls'; //para inserir o nome da página de forma editável no documento
import './index.css';

// encontra a seção de upload padrão
const UploadSection = DEFAULT_SECTIONS.find(
  (section) => section.name === 'upload'
  
);
// substitui seu componente de painel
UploadSection.Panel = UploadPanel;

//--------------------------

// Encontra a seção de tamanho padrão
const SizeSection = DEFAULT_SECTIONS.find(
  (section) => section.name === 'size'
);


// Deixa o componente de painel invisível
SizeSection.Panel.visibleInList = false;

// Deixa a seção de tamanho invisível
SizeSection.visibleInList =false;

// Renderiza a sidepanel verificando a propriedade isVisible do componente de painel e da seção
{DEFAULT_SECTIONS.map((section) =>
  section.visibleInList !== false && section.Panel && section.Panel.visibleInList !== false ? (
    <div key={section.name}>
      <h2>{section.name}</h2>
      <section.Panel />
    </div>
  ) : null
)}

//------ Organizar menu lateral sidpanel

// DEFAULT_SECTIONS.splice(3, 0, IllustrationsSection);
// replace elements section with just shapes
DEFAULT_SECTIONS.splice(8, 0, MyDesignsSection);
DEFAULT_SECTIONS.splice(0, 1, CapaSection);
//DEFAULT_SECTIONS.splice(0, 8, ComecarLivroSection);//aqui eu substitui a seção padrão Templates (0) pela secção Miolo (1) 
//DEFAULT_SECTIONS.splice(3, 1, ShapesSection); // substitui a seção existente na posição 3 do array por uma nova seção chamada "ShapesSection" 
DEFAULT_SECTIONS.splice(1, 2, TextSection); 
DEFAULT_SECTIONS.splice(4, 0, PhotosSection); 
DEFAULT_SECTIONS.splice(3, 0, IconsSection); //adicionando uma nova seção chamada "IconsSection" na posição 3 do array.
DEFAULT_SECTIONS.splice(7, 1, IASection);
DEFAULT_SECTIONS.splice(8, 1, LayersSection);
DEFAULT_SECTIONS.splice(8, 0, StableDiffusionSection);
DEFAULT_SECTIONS.splice(8, 0, EdicaoProSection );
DEFAULT_SECTIONS.splice(8, 0, PostCustom );
DEFAULT_SECTIONS.splice(8, 0, TutorialSection );
DEFAULT_SECTIONS.splice(8, 0, QuotesSection);
DEFAULT_SECTIONS.splice(8, 0, CatalogacaoSection);
DEFAULT_SECTIONS.splice(8, 0, MioloSection); 
DEFAULT_SECTIONS.splice(3, 0, ToolsSection);
DEFAULT_SECTIONS.splice(4, 0, SizeTabCustom);
DEFAULT_SECTIONS.splice(4, 0, QrSection);
//DEFAULT_SECTIONS.splice(4, 0, SizeSection); //adicionando uma nova seção chamada "IconsSection" na posição 3 do array.
// add two more sections
//DEFAULT_SECTIONS.push(QuotesSection, QrSection); //adicionando duas novas seções chamadas "QuotesSection" e "QrSection" ao final do array

const useHeight = () => {
  const [height, setHeight] = React.useState(window.innerHeight);
  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight);
    });
  }, []);
  return height;
};

const MyColorPicker = observer(({ element }) => {
  // store - main polotno store object
  // elements - array of selected elements. The same as store.selectedElements
  // element - first selected element. The same as store.selectedElements[0]
  return (
    <div>
      <input
        type="color"
        value={element.fill}
        onChange={(e) => {
          element.set({
            fill: e.target.value,
          });
        }}
      />
    </div>
  );
});


const App = ({ store }) => {
  const height = useHeight();

  const handleDrop = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    // skip the case if we dropped DOM element from side panel
    // in that case Safari will have more data in "items"
    if (ev.dataTransfer.files.length !== ev.dataTransfer.items.length) {
      return;
    }
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      loadFile(ev.dataTransfer.files[i], store);
    }


  };  
  return (      
    <div
      style={{
        width: '99.1vw',
        height: height + 'px',
        display: 'flex',
        flexDirection: 'column',
      }}
      onDrop={handleDrop} >     

      <Topbar store={store} />
      <div style={{ height: 'calc(100% - 32px)' }}>
        
      <PolotnoContainer >
          <SidePanelWrap>
          <SidePanel 
            store={store} 
            sections={DEFAULT_SECTIONS}
             defaultSection="capa"              
            />
          </SidePanelWrap>
          <WorkspaceWrap>
            
            <Toolbar
             //downloadButtonEnabled
              store={store}
              
              components={{               
                 
                TextFill: MyColorPicker,
              }}
           
            />
            <Workspace 
            store={store} 
            components={{
              NoPages: ({ store }) => 
             <center> <div className='desktop-only' > 
              
              <img src="./assets/menu_Modelos.png" width={'100%'}  height={'100%'}  />
             
            
              </div>  

              <div className='mobile-only' >               
              <img src="./assets/menu_Modelos_cel.png" width={'100%'} height={'100%'}  />
            
            
              </div> 

              </center>       
            }}

            // components={{ PageControls }}  
                    
            backgroundColor="#000000" //fundo escuro                      
            pageControlsEnabled={true}   //controle de visualização de menu para add páginas e excluir páginas           
            activePageBorderColor="red" //contorno nas páginas     
               
            />
            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </PolotnoContainer>
      </div>
    </div>
  );
};

export default App;


