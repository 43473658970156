import React from 'react';
import ReactDOM from 'react-dom/client';

import { createStore } from 'polotno/model/store';

import { setTranslations, getTranslations } from 'polotno/config';
import { pxToUnitRounded, unitToPx, pxToUnit } from 'polotno/utils/unit';
//import Marca from './imagens/plataforma-eli-como-usar-a.png';

import './index.css';
import App from './App';
//import './logger';
//import { createProject } from './project';


import { unstable_useHtmlTextRender } from 'polotno/config';

unstable_useHtmlTextRender(true);


const store = createStore({
  //key: 'cWIIWTmkMgSoPVuDcMiK', //
  //key: 'nFA5H9elEytDyPyvKL7T', // API KEY STUDIO
  key: '_ekCpIFSAvKMy7UTpmTd', // minha API KEY
   showCredit: false,
 });
 
 // store.addPage({
   //background: 'white', // default is "white"   
   
  // });

// add page and element instantly


const checkPageNumbers = () => {
  // check every page
  store.pages.forEach((page, index) => {
    // lets try to find existing page number element
    let pageNumber = page.children.find(
      (el) => el.custom?.name === 'page-number'
    );
    const text = `${index + 1}`;
    if (pageNumber) {
      // if such element is already here, let's update it
      // in the case if a user reoderer pages
      pageNumber.set({ text });
    } else {
      // or just create a new one
      page.addElement({
        type: 'text',
        custom: { name: 'page-number' },
        text,
        width: store.width,
        align: 'center',
        fontSize: 80,
        x: 0,
        y: store.height - 250,
        selectable: true,
        contentEditable: true,
        styleEditable: true,

                
        //alwaysOnTop: true,

      });
    }
  });
};

checkPageNumbers();
store.on('change', checkPageNumbers);


   
//imagem para aparecer no primeiro acesso do usuário

/*store.activePage.addElement({

  type: "image",
    src: Marca,
    height: 21,
    width: 14,
    x: 0,
    y: 0,
    keepRatio: false,
     // can element be moved and rotated
     draggable: false,
     // can we change content of element?
     contentEditable: false,
     // can we change style of element?
     styleEditable: false,
     // can we resize element?
     resizable: false,
  });*/

  
store.setSize(2480 , 3507); //define o tamanho do canva
 
 //store.activePage.set({ bleed: 80}); // definir sangria em pixels (118px aprox. 1cm)
 //store.toggleBleed(true);
 
 store.setUnit({
   unit: 'cm', // mm, cm, in, pt, px
   dpi: 300,
 });
 
  //convert 100 pixels para mm com 300 DPI
 var mm = pxToUnit({
   px: 100,
   unit: 'mm',
   dpi: 300,
 });
 
 
 // faça o mesmo, mas com arredondamento de 2 dígitos, para que o número não pareça feio na interface do usuário
 var mm = pxToUnitRounded({
   px: 100,
   unit: 'mm',
   precious: 2,
   dpi: 300,
 });
 
 // convert 30 mm to pixels with 300 DPI
 var pixels = unitToPx({
   unit: 'mm',
   dpi: 300,
   unitVal: 30,
 });
 
 //traduzindo alguns termos

 //console.log(getTranslations()); //console para exibir o esquema de chaves de tradução

setTranslations({
  sidePanel: {
    templates: 'Formatos',
    text: 'Texto',
    photos: 'Fotos',
    elements: 'Formas',
    upload: 'Enviar',
    background: 'Fundo',
    layers: 'Camadas',
    resize: 'Tamanhos',
    searchPlaceholder: 'Procurar',
    myFonts: 'Minhas fontes',
    createHeader: 'Criar Título',
    createSubHeader: 'Criar Subtítulo',
    createBody: 'Criar Parágrafos',
    uploadImage: 'Envie seu desenho',
    noResults: 'Sem elementos para exibir no momento.',
    uploadTip: 'Envie suas próprias imagens aqui!',
    error: 'Ops, tivemos um problema ao carregar... Tente novamente.',
    uploadFont: 'Envie sua fonte',
    lines: 'Linhas',
    shapes: 'Formas',
    layersTip: 'Elementos em sua página ativa:',
    searchTemplatesWithSameSize: 'Mostrar modelos com o mesmo tamanho',
    search: 'Procurar',

   },

   toolbar: {
    position: "Posições",
    flip: 'Inverter',
    crop: 'Recorte',
    effects: 'Efeitos',
    layering: 'Ordenar',
    groupElements: 'Agrupar',
    ungroupElements: 'Desagrupar',
    transparency: 'Transparência',
    download: 'Download',
    removeBackground: 'Remover Fundo',
    up:'Frente',
    down: 'Trás',
    toForward: 'Pra Frente da Página',
    toBottom: 'Pra Trás da Página',
    alignLeft: 'a esquerda',
    alignRight: 'a direita',
    alignCenter: 'ao centro',
    alignTop: 'no topo',
    alignMiddle: 'no meio',
    alignBottom: 'no rodapé',
    removeElements: 'Apagar Item',
    duplicateElements: 'Duplicar Item',
    fitToBackground: 'Fixar ao Fundo',
    removeBackgroundTitle: 'Removedor de fundo de imagens',
    flipHorizontally: 'Horizontalmente',
    flipVertically: 'Verticalmente',
    cropDone: 'Confirmar',
    cropCancel: 'Cancelar',
    lockedDescription: 'O objeto está bloqueado. Desbloqueie-o para permitir alterações na tela.',
    unlockedDescription: 'Objeto desbloqueado. Edite como quiser!',
    letterSpacing: 'Espaçamento Letras',
    lineHeight: 'Espaçamento Linhas',
    lineSize: 'Line size',
    backgroundCornerRadius: 'Arredondar cantos',
    cornerRadius: 'Arredondar cantos',
    border: 'Bordas',
    brightness: 'Brilho',
    grayscale: 'Escala Cinza',
    shadow: 'Sombra',
    blur: 'Desfocar', 

  },

  workspace: {
    addPage: '➕ ADD PÁGINA',
    removePage: 'REMOVER',
    duplicatePage: 'DUPLICAR', 
    fitToPage: 'Fixar',    
    noPages: '',    
  },

   error: {
    removeBackground: 'Ops! Algo deu errado. O fundo não pode ser removido.',

  },
 
});

//const project = createProject({ store });
//window.project = project;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App store={store} />);