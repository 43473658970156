import React from 'react';
import '../index';
import {useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { SectionTab} from 'polotno/side-panel';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import BsFilePost from '@meronex/icons/bs/BsFilePost';


export const PostPanel = observer(({ store }) => {
  // load data

  const [searchTerm] = useState('');
  const { data, isLoading } = useInfiniteAPI({
    getAPI: ({ page }) => `posts/page${page}.json`,
  });

  function filterData(data, searchTerm) {
    if (!searchTerm) {
      return data;
    }
    return data.filter(item => item.json.includes(searchTerm) || item.preview.includes(searchTerm));
  }

 
  return (
    <div>
      
      <div>
           
      <div>
     
        </div>
        </div>
        <div>
        
     <div className='templates-section'>      
    
    <div><p>Repositório de Posts</p></div>
    
    
    {/*<div className='texto-tab-anuncio'>
<p>Espaço reservado para anuncios mais recusrsos.</p>
        </div>*/}

    <div className='scrollable-container-post'>
    <ImagesGrid
        shadowEnabled={false}
        images={filterData(data?.map((data) => data.items).flat(), searchTerm)}
        getPreview={(item) => `./posts/${item.preview}`}
        isLoading={isLoading}
        onSelect={async (item) => {
          // download selected json
          const req = await fetch(`./posts/${item.json}`);
          const json = await req.json();
          // just inject it into store
          store.loadJSON(json);
        }}
        // numero de colunas da tab MODELOS
        rowsNumber={2}
        loadMore={true}

      />
       </div>
      </div>
    </div>
    </div>
       
    
    );
});


// define the new custom section
export const PostCustom = {
  name: 'posts',
  Tab: (props) => (
    <SectionTab name="Criar Posts" {...props}>
      <BsFilePost />
    </SectionTab>
  ),
  visibleInList: false,
  // we need observer to update component automatically on any store changes
  Panel: PostPanel,
  
};