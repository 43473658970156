import React, { useRef, useState } from 'react';
import IosArrowDropdownCircle from '@meronex/icons/ios/IosArrowDropdownCircle';
import {
  Button,
  Dialog,
  Classes,
  Position,
  Menu,
  MenuItem,
  MenuDivider,
  Divider,
  InputGroup,
  Tab, 
  Tabs,
} from '@blueprintjs/core';

import { observer } from 'mobx-react-lite';
import { SectionTab } from 'polotno/side-panel';
import FaBrain from '@meronex/icons/fa/FaBrain';

import '../index.css';


function RemoverElementos() {

  const [faqOpened, toggleFaq] = React.useState(false);
        
      return (
        <>  
        <div>
          </div> 
         <center><h3>🫧Removedor de Elementos</h3></center>
        <br/><br/>
         <center>
          <p>Com essa ferramenta desenvolvida com Inteligência Artificial, 
            você poderá remover qualquer objetos indesejados de uma imagem de rápida e precisa.<br/></p>     
          </center>
        
        <br/><br/>
        <Button
              text="ACESSAR"
              icon="circle"
              onClick={() => {
                toggleFaq(true);
              }}
            />             
        
           
          <Dialog
              icon=""
              onClose={() => toggleFaq(false)}
              title="REMOVEDOR DE ELEMENTOS - Remova com nossa IA qualquer elemento de sua foto!"
              isOpen={faqOpened}
              style={{
                width: '100%',
               // height: '720vh',
                marginTop: '0px',           
                
              }}
            >
             
              <div className={Classes.DIALOG_BODY}>
              <right>            
                  <Button  onClick={() => toggleFaq(false)}>FECHAR (X) </Button>        
                </right>    
                <center>
                <iframe
              src="https://itacaiunas-remove-photo-object.hf.space"
              frameborder="0"
              width="850"
              height="13850vh"
              
            ></iframe>
                </center>
       
                <br/>
    
                <right>          
                  
              <Button  onClick={() => toggleFaq(false)}>FECHAR CHAT</Button>        
            </right>            
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              
            </div>
          </div>
          </div>
        </Dialog>
</>
             
    );
  }

  function EdicoesIa() {
    
    const [faqOpened1, toggleFaq1] = React.useState(false);
    
          
        return (
          <>
         <div>
          
          <center>
          <div>
          </div>

                  

            </center></div> 
         <center><h3>📸Removedor de Fundo</h3></center>
        <br/><br/>
         <center>
          <p>Com essa ferramenta desenvolvida com Inteligência Artificial, 
            você poderá remover o fundo de suas imagens de forma rápida e precisa.<br/></p>  
       </center>

       {/*CHAMAR POPUP DO IMBUTIDO AO ELI*/}
       <br/><br/>
            <Button
              text="ACESSAR"
              icon="circle"
              onClick={() => {
                toggleFaq1(true);
              }}
            />
<Dialog
          icon="msg"
         onClose={() => toggleFaq1(false)}
          title=" REMOVA O FUNDO DE SUA IMAGEM!"
          isOpen={faqOpened1}
          style={{
            width: '100%',
            marginTop: '0px',
            
          }}
        >
          <div className={Classes.DIALOG_BODY}>
          <right>            
              <Button  onClick={() => toggleFaq1(false)}>FECHAR (X) </Button>        
            </right>    
            <center>
            <iframe
          src="https://itacaiunas-removedor-fundo.hf.space"
          frameborder="0"
          width="850"
          height="13850vh"
          
        ></iframe>
            </center>
   
            <br/>

            <right>                        
              <Button  onClick={() => toggleFaq1(false)}>FECHAR CHAT</Button>        
            </right>            
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              
            </div>
          </div>
          </div>
        </Dialog>
       </>

       );
  }

  function Introducao() {
    
    const [faqOpened, toggleFaq] = React.useState(false);
    
          
        return (
          <>
         <div>
          <center><img src="" width="60%"/></center></div> 
         <center><h3>Edição de Imagens com IA</h3></center>
        
         <center>
          <p>Remova o fundo de suas imagens de forma rápida e precisa.<br/></p>  
       </center>

       {/*CHAMAR POPUP DO IMBUTIDO AO ELI*/}

            <Button
              text="Assistir Tutorial"
              icon=""
              onClick={() => {
                toggleFaq(true);
              }}
            />
<Dialog
          icon="msg"
         onClose={() => toggleFaq(false)}
          title="Tutorial introdutório Edição de imagens com IA"
          isOpen={faqOpened}
          style={{
            width: '100%',
            marginTop: '0px',
            
          }}
        >
          <div className={Classes.DIALOG_BODY}>
          <right>            
              <Button  onClick={() => toggleFaq(false)}>FECHAR (X) </Button>        
            </right>    
            <center>
    EM BREVE
            </center>
   
            <br/>
           
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              
            </div>
          </div>
          </div>
        </Dialog>
       </>

       );
  }

const EdicaoProPanel = observer(({ store }) => {
  const [selectedTabId, setSelectedTabId] = React.useState('edicoes-ia');
  return (
    
    
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Button
            text="Voltar"
            //intent="none"
            icon="arrow-left"
            onClick={() => {
              store.openSidePanel('tools');            
            }}
            style={{
              width: '180px',
              height: '20px',
              fontSize: '15px',
              fontWeight: 'bold',
              alignItems: 'center',
              color: 'black',
              backgroundColor: '#ffffff',
              borderRadius: '30px' // Adicionando a propriedade borderRadius
         
            }}
          />  
          
      <Tabs
        id="TabsExample"
        defaultSelectedTabId="edicoes-ia"
        onChange={(tabId) => {
          setSelectedTabId(tabId);
        }}
      >
        {/* <Tab id="generate" title="🏰 Stable Diffusion" />*/}
      {/* <Tab id="introducao" title=" ✍🏽 Edite suas fotos" />*/}
        <Tab id="edicoes-ia"  title=" 📸Apagar Fundo"/>
        <Tab id="elementos"  title="🫧Apagar Objetos"/>
     

      </Tabs>
      <div
        style={{
          height: 'calc(100% - 20px)',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '20px',
        }}
      >
        {selectedTabId === 'introducao' && <Introducao store={store} />}
        {selectedTabId === 'edicoes-ia' && <EdicoesIa store={store} />}
        {selectedTabId === 'elementos' && <RemoverElementos store={store} />}
        
       
      </div>
    </div>
  );
});

// define the new custom section
export const EdicaoProSection = {
  name: 'edicao-pro',
  Tab: (props) => (
    <SectionTab name="Edição PRO" {...props}>
      <FaBrain />
    </SectionTab>
  ),
  visibleInList: false,
  // we need observer to update component automatically on any store changes
  Panel: EdicaoProPanel,
};
