import React from 'react';
import '../index.css';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { SectionTab } from 'polotno/side-panel';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import BsFilePost from '@meronex/icons/bs/BsFilePost';
import {
  Button,
  AnchorButton,  
} from '@blueprintjs/core';

export const TemplatesMiolos = observer(({ store }) => {
  // load data

  const [searchTerm, setSearchTerm] = useState('');
  const { data, isLoading } = useInfiniteAPI({
    getAPI: ({ page }) => `posts/page${page}.json`,
  });

  function filterData(data, searchTerm) {
    if (!searchTerm) {
      return data;
    }
    return data.filter(item => item.json.includes(searchTerm) || item.preview.includes(searchTerm));
  }

 
  return (
    <div>
      
      <div>
           
      <div>
     
        </div>
        </div>
        <div>
        
        <Button
            text="Voltar"
            //intent="none"
            icon="arrow-left"
            onClick={() => {
              store.openSidePanel('tools');            
            }}
            style={{
              width: '180px',
              height: '20px',
              fontSize: '15px',
              fontWeight: 'bold',
              alignItems: 'center',
              color: 'black',
              backgroundColor: '#ffffff',
              borderRadius: '30px' // Adicionando a propriedade borderRadius
         
            }}
            ></Button>

<h3 style={{ marginBottom: '10px', marginTop: '5px' }}>
          <BsFilePost/> Criador de Post  </h3>
     
    <div className='scrollable-container-capa'>
   {/* <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="🔎 Digite sua pesquisa aqui" style={{color: '#555', width: '70%', padding: '10px', margin: '0.3cm' }} />*/}


    <ImagesGrid
        shadowEnabled={false}
        images={filterData(data?.map((data) => data.items).flat(), searchTerm)}
        getPreview={(item) => `./posts/${item.preview}`}
        isLoading={isLoading}
        onSelect={async (item) => {
          // download selected json
          const req = await fetch(`./posts/${item.json}`);
          const json = await req.json();
          // just inject it into store
          store.loadJSON(json);
        }}
        // numero de colunas da tab MODELOS
        rowsNumber={2}
      />

<hr/>
        
        <div style={{
        fontSize: '18,5px', 
        fontWeight: 'bold',
        color: 'steelblue',
        textAlign: 'center'
        

      }}>
        Acesso completo no Plano Plus💠!
      <br/>
        </div>
        <center>
          <p>Tenha acesso a divermos modelos de artes para divulgar seu e-book nas redes sociais!</p>  
          <AnchorButton
            intent="success"
            href="https://fabricadoebook.com.br/assinar-plano/"
            target="_blank"
           //</div> icon={
             // <FcPlus className="bp4-icon" style={{ fontSize: '20px' }} />
            //}          
          style={{
            width: '180px',
            height: '20px',
            fontSize: '15px',
            fontWeight: 'bold',
            alignItems: 'center',
            color: 'white',
            backgroundColor: '#8c9c7b',
            borderRadius: '30px' // Adicionando a propriedade borderRadius
       
          }}
          >
            Desbloquear tudo
          </AnchorButton>

          </center>

        <br/><br/>
        <img src="./assets/divulg_posts_pro.png" width="300px" ></img>              
        <br/><br/>
       <center>
        <b>         
          <p>Todos os meses são adicionados mais modelos na versão Plus da Fábrica do Livro.</p>
          </b>   
          <AnchorButton
            intent="success"
            href="https://fabricadoebook.com.br/assinar-plano/"
            target="_blank"
           //</div> icon={
             // <FcPlus className="bp4-icon" style={{ fontSize: '20px' }} />
            //}          
          style={{
            width: '180px',
            height: '20px',
            fontSize: '15px',
            fontWeight: 'bold',
            alignItems: 'center',
            color: 'white',
            backgroundColor: '#8c9c7b',
            borderRadius: '30px' // Adicionando a propriedade borderRadius
       
          }}
          >
            Saiba mais!
          </AnchorButton>
          </center>
      
      </div>

      

  {/*  

       <br/>
    <div className='texto-tab'>
<p>Espaço reservado para anuncios mais recusrsos.</p>
  
      </div>
*/}

    </div>
      </div> 
    
    );
});

// define the new custom section
export const MioloSection = {
  name: 'miolo',
  Tab: (props) => (
    <SectionTab name="Posts Social" {...props}>
      <BsFilePost/>
    </SectionTab>
  ),
   visibleInList: false,

  // we need observer to update component automatically on any store changes
  Panel: TemplatesMiolos,
  
};