import React, { useRef, useState } from 'react';
import FcPlus from '@meronex/icons/fc/FcPlus';

import {
  Button,
  Dialog,
  Classes,
  Position,
  AnchorButton,
  Menu,
  MenuItem,
  MenuDivider,
  Divider,
  InputGroup,
  Tab, 
  Tabs,
} from '@blueprintjs/core';

import { observer } from 'mobx-react-lite';
import { SectionTab } from 'polotno/side-panel';
import { getKey } from 'polotno/utils/validate-key';
import { getImageSize } from 'polotno/utils/image';
import FaBrain from '@meronex/icons/fa/FaBrain';
import { t } from 'polotno/utils/l10n';

import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { useCredits } from '../credits';
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { getCrop } from 'polotno/utils/image';

import '../index.css';


const API = 'https://api.polotno.dev/api';
const GenerateTab = observer(({ store }) => {
  const inputRef = React.useRef(null);
  const [image, setImage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { credits, consumeCredits } = useCredits('stableDiffusionCredits', 200);
  const abrirPopup = () => {
    window.open('https://translate.google.com.br/?sl=pt&tl=en&op=translate', 'Google Translate', 'width=800,height=600');
  }

  const handleGenerate = async () => {
    if (credits <= 0) {
      alert('You have no credits left');
      return;
    }
    setLoading(true);
    setImage(null);

    const req = await fetch(
      `${API}/get-stable-diffusion?KEY=${getKey()}&prompt=${
        inputRef.current.value
      }`
    );
    setLoading(false);
    if (!req.ok) {
      alert('Algo deu errado, tente novamente...');
      return;
    }
    consumeCredits();
    const data = await req.json();
    setImage(data.output[0]);
  };

  return (
    <>
     
      <center><div >
      <h3>🎨 Ilustre com IA</h3>  
      <div style={{
            fontSize: '13,5px',
            fontWeight: 'bold',
            color: 'steelblue',
            textAlign: 'center'            
          }}>
          ✨ Stable Diffusion é um modelo latente de difusão texto-imagem capaz de gerar imagens fotorrealistas 
        a partir de qualquer entrada de texto.<br/><br/>
          </div> 
     
    
      <button 
      intent="primary" 
      onClick={abrirPopup}>
        Traduzir Português/Inglês.
        </button>
        
    </div><br/>
      </center>
      <InputGroup
        placeholder="Escreva ou cole seu prompt aqui..."
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleGenerate();
          }
        }}
        style={{
          marginBottom: '0.8px',
        }}
        inputRef={inputRef}
      />
      <p style={{ textAlign: 'center' }}>
        {!!credits && <div>Você tem ({credits}) créditos para usar hoje. </div>}
        {!credits && <div>Seus créditos acabaram. Aguarde até amanhã que eles serão renovados.</div>}
      </p>
      <Button
        onClick={handleGenerate}
        intent="primary"
        loading={loading}
        style={{ marginBottom: '10px' }}
        disabled={credits <= 0}
      >
        CRIAR IMAGEM 🎨
      </Button>

      <MenuDivider />
{/*
<center><h4>Assine a versão Plus e tenha créditos ilimitados!</h4>

           <AnchorButton
            intent="none"
            href="https://editoraitacaiunas.com.br/editor-de-livros-online-itacaiunas/"
            target="_blank"
            
          >
            ASSINAR PLUS💠 
          </AnchorButton>
          </center>
          <br/>

      */}
      
  

      {image && (
        <ImagesGrid
          shadowEnabled={false}
          images={image ? [image] : []}
          getPreview={(item) => item}
          isLoading={loading}
          onSelect={async (item, pos, element) => {
            const src = item;
            if (element && element.type === 'svg' && element.contentEditable) {
              element.set({ maskSrc: src });
              return;
            }

            if (
              element &&
              element.type === 'image' &&
              element.contentEditable
            ) {
              element.set({ src: src });
              return;
            }

            const { width, height } = await getImageSize(src);
            const x = (pos?.x || store.width / 2) - width / 2;
            const y = (pos?.y || store.height / 2) - height / 2;
            store.activePage?.addElement({
              type: 'image',
              src: src,
              width,
              height,
              x,
              y,
            });
          }}
          rowsNumber={1}
        />
      )}
    </>
  );
});


const RANDOM_QUERIES = [
  'Magic mad scientist, inside cosmic labratory, radiating a glowing aura stuff, loot legends, stylized, digital illustration, video game icon, artstation, ilya kuvshinov, rossdraws',
  'cute kitten sitting in a canoe, photography, minimalistic, 8 k ',
  'anime cyperpunk',
  'an mascot robot, smiling, modern robot, round robot, cartoon, flying, fist up, crypto coins background',
  'Outer space, cosmic rocket, radiating a glowing aura, legends of loot, stylized, digital illustration, video game icon, arts station, rossdraws',
];

const SearchTab = observer(({ store }) => {
  // load data
  const [query, setQuery] = React.useState('');
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const [delayedQuery, setDelayedQuery] = React.useState(
    RANDOM_QUERIES[(RANDOM_QUERIES.length * Math.random()) | 0]
  );
  const abrirPopup = () => {
    window.open('https://translate.google.com.br/?sl=pt&tl=en&op=translate', 'Google Translate', 'width=800,height=600');
  }

  const requestTimeout = React.useRef();
  React.useEffect(() => {
    requestTimeout.current = setTimeout(() => {
      setDelayedQuery(query);
    }, 1000);
    return () => {
      clearTimeout(requestTimeout.current);
    };
  }, [query]);

  React.useEffect(() => {
    if (!delayedQuery) {
      return;
    }
    async function load() {
      setIsLoading(true);
      setError(null);
      try {
        const req = await fetch(
          `https://lexica.art/api/v1/search?q=${delayedQuery}`
        );
        const data = await req.json();
        setData(data.images);
      } catch (e) {
        setError(e);
      }
      setIsLoading(false);
    }
    load();
  }, [delayedQuery]);

  return (
    <>
    <h3> Lexica IA 🖌️</h3>
    <p>
      Pesquise e use imagens criadas pela IA {' '} Lexica.
       {/* <a href="" target="_blank">        
        </a>*/}             
      </p>
      <InputGroup
        leftIcon="search"
        placeholder={t('sidePanel.searchPlaceholder')}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        type="search"
        style={{
          marginBottom: '20px',
        }}
      />
      
      <button intent="primary" onClick={abrirPopup}>Traduza seu texto para o Inglês</button><br/>
      

      <div className='scrollable-container-capa'>
      <ImagesGrid
        shadowEnabled={false}
        images={data}
        getPreview={(item) => item.srcSmall}
        isLoading={isLoading}
        error={error}
        onSelect={async (item, pos, element) => {
          if (element && element.type === 'svg' && element.contentEditable) {
            element.set({ maskSrc: item.src });
            return;
          }

          const { width, height } = await getImageSize(item.srcSmall);

          if (element && element.type === 'image' && element.contentEditable) {
            const crop = getCrop(element, {
              width,
              height,
            });
            element.set({ src: item.src, ...crop });
            return;
          }

          const x = (pos?.x || store.width / 2) - width / 2;
          const y = (pos?.y || store.height / 2) - height / 2;
          store.activePage?.addElement({
            type: 'image',
            src: item.src,
            width,
            height,
            x,
            y,
          });
        }}
        rowsNumber={2}
      />
      </div>
    </>   
  );
});


function AlviTab() {

  const [faqOpened, toggleFaq] = React.useState(false);
    
        
      return (
        <>
       
     {/*  <center> <h2>Tavernard 1.0</h2>  </center>

       <div style={{
            fontSize: '13,5px',
            fontWeight: 'bold',
            color: 'steelblue',
            textAlign: 'center'            
          }}>
          ✨ Consulte o Tavernard 1.0 para tirar dúvidas e pedir dicas para melhorar ainda mais a sua obra. Com Tavernard,
          você tem um assistente super inteligente que conversa com você de forma natural . <br/><br/>
          </div> 
             
            
        <br/>      */}     

{/*CHAMAR POPUP DO IMBUTIDO AO ELI*/}

     {/* <center><Button
              text="Conversar 💬"
              onClick={() => {
              toggleFaq(true);
              }}
              style={{
                width: '300px',
                height: '40px',
                fontSize: '20px',
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: '#8c9c7b'
                                               
              }}
            />
            
            </center>
            
            <br/>*/}
           
       

      
        {/*
          <center><h4>Tenha acesso a uma biblioteca avançada de prompts para extrair o melhor da Inteligência Articial GPT para sua obra. </h4>
           <AnchorButton
            intent="none"
            href="https://editoraitacaiunas.com.br/editor-de-livros-online-itacaiunas/"
            target="_blank"
            >
            ASSINAR PLUS💠
          </AnchorButton>
          </center>
          */}
    
         
    <Dialog
          icon="msg"
         onClose={() => toggleFaq(false)}
          title=" 💬 Converse com o Assistente Literário com IA Tavernard!"
          isOpen={faqOpened}
          style={{
            width: '100%',
            marginTop: '0px',
            
          }}
        >
          <div className={Classes.DIALOG_BODY}>
            <div>
                <iframe src="https://editoraitacaiunas.com.br/tavernard-1-0-assistente-literario-com-ia/" width="100%" height=" 650vh"></iframe>
            </div>
            <br/>
            <right>            
              <Button  onClick={() => toggleFaq(false)}>FECHAR CHAT</Button>        
            </right>            
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              
            </div>
          </div>
          </div>
        </Dialog>
</>
             
    );
  }

  
const StableDiffusionPanel = observer(({ store }) => {
  const [selectedTabId, setSelectedTabId] = React.useState('generate');
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >

<Button
            text="Voltar"
            //intent="none"
            icon="arrow-left"
            onClick={() => {
              store.openSidePanel('tools');            
            }}
            style={{
              width: '180px',
              height: '20px',
              fontSize: '15px',
              fontWeight: 'bold',
              alignItems: 'center',
              color: 'black',
              backgroundColor: '#ffffff',
              borderRadius: '30px' // Adicionando a propriedade borderRadius
         
            }}
          />  
      <Tabs
        id="TabsExample"
        defaultSelectedTabId="generate"
        onChange={(tabId) => {
          setSelectedTabId(tabId);
        }}
      >
         
          
         <Tab id="generate" title="🎨Texto-Imagem" />
        <Tab id="search" title="🖌️	Lexica" />
      {/*   <Tab id="alvi"  title="Tavernard 📝"/>*/}
      

      </Tabs>
      <div
        style={{
          height: 'calc(100% - 20px)',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '20px',
        }}
      >
        {selectedTabId === 'generate' && <GenerateTab store={store} />}
        {selectedTabId === 'search' && <SearchTab store={store} />}
        {selectedTabId === 'alvi' && <AlviTab store={store} />}
        
       
      </div>
    </div>
  );
});

// define the new custom section
export const StableDiffusionSection = {
  name: 'stable-diffusion',
  Tab: (props) => (
    <SectionTab name="IA Criadora" {...props}>
      <FaBrain />
    </SectionTab>
  ),
  visibleInList: false,
  // we need observer to update component automatically on any store changes
  Panel: StableDiffusionPanel,
};
