import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SectionTab } from 'polotno/side-panel';
import { Button, FormGroup, InputGroup } from '@blueprintjs/core';
import CgSize from '@meronex/icons/cg/CgSize';

const AVAILABLE_SIZES = [
 // { width: 140, height: 210, label: '14 x 21 cm' },
 // { width: 15, height: 21, label: '15 x 21 cm (A5)' },
  //{ width: 200, height: 200, label: '20 x 20 cm' },
 // { width: 210, height: 297, label: '21 x 29.7 cm (A4)' },
];

const SizeTabCustom = {
  name: 'size',
  Tab: (props) => (
    <SectionTab name="Tamanhos" {...props}>
      <CgSize />
    </SectionTab>
    ),
    visibleInList: false,
  Panel: observer(({ store }) => {
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');

    const handleSizeChange = () => {
      const parsedWidth = parseInt(width, 10);
      const parsedHeight = parseInt(height, 10);

      if (!isNaN(parsedWidth) && !isNaN(parsedHeight)) {
        const scaledWidth = parsedWidth * 100;
        const scaledHeight = parsedHeight * 100;
        store.setSize(scaledWidth, scaledHeight);
      }
    };

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleSizeChange();
      }
    };

    return (
      <div>         
        
        {AVAILABLE_SIZES.map(({ width, height, label }, i) => (
          <Button
            key={i}
            style={{ width: '100%', marginBottom: '20px' }}
            onClick={() => {
              store.setSize(width, height);
            }}
          >
            {label}
          </Button>
        ))}
        
        <div style={{
            fontSize: '13,5px',
            fontWeight: 'normal',
            color: 'steelblue',
            textAlign: 'center'            
          }}>
         <h2>Definir Tamanho (cm)</h2>
          </div>  

        <FormGroup label="Largura">
          <InputGroup
            value={width}
            onChange={(e) => setWidth(e.target.value)}
            placeholder="Largura em cm"
          />
        </FormGroup>
        <FormGroup label="Altura">
          <InputGroup
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            placeholder="Altura em cm"
            onKeyDown={handleKeyPress}
          />
        </FormGroup>
        <Button onClick={handleSizeChange}>DEFINIR</Button>

        <div style={{
            fontSize: '13,5px',
            fontWeight: 'normal',
            color: 'steelblue',
            textAlign: 'center'            
          }}>
          <h3>Exemplos</h3>
          <p>21 x 29.7 cm (A4)<br/>          
            15 x 21 cm (A5)<br/>
            14 x 21 cm <br/>
            20 x 20 cm</p>

          </div> 

      </div>

      
    );
  }),
};

export default SizeTabCustom;
