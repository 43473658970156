import React from 'react';
import BisBookAlt from '@meronex/icons/bi/BisBookAlt';
import '../index.css';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { SectionTab } from 'polotno/side-panel';
import FaBrain from '@meronex/icons/fa/FaBrain';

import {
  Button,
  AnchorButton,
  Dialog,
  Classes,
  Position,
  Menu,
  MenuItem,
  MenuDivider,
  Divider,
  InputGroup,
  Tab, 
  Tabs,
} from '@blueprintjs/core';

export const IAPanel = observer(({ store }) => {
  const [searchTerm, setSearchTerm] = useState('');

   return (
    <div>    
            
               
      <div className='templates-section'>
        
        <div style={{
           
            fontWeight: 'bold',
            color: 'green',
            textAlign: 'center'
            
          }}>
            
   <h2>IA CRIATIVA </h2>    
          </div> 

        <div className='scrollable-container-miolo'>
        <div style={{
            fontSize: '12,0px',
            fontWeight: 'normal',
            color: 'steelblue',
            textAlign: 'center'
            
          }}>
        Sejam bem vindos a IA Criativa. Utilize nossas ferramentas de Inteligência Artificial (IA) para auxiliar no seu processo criativo.
          </div> 

    <br/>
<center>

            <p>
           
           {/*
            <AnchorButton
            intent="success"
            href="https://editoraitacaiunas.com.br/editor-de-livros-online-itacaiunas/"
            target="_blank"
           //</div> icon={
             // <FcPlus className="bp4-icon" style={{ fontSize: '20px' }} />
            //}          
          style={{
            width: '180px',
            height: '20px',
            fontSize: '15px',
            fontWeight: 'bold',
            alignItems: 'center',
            color: 'white',
            backgroundColor: '#8c9c7b',
            borderRadius: '30px' // Adicionando a propriedade borderRadius
       
          }}
          >
            Desbloquear tudo
        </AnchorButton> */}
           
            <Button
            text="🧠 CRIADOR IA"
            intent="none"           
            onClick={() => {
              store.openSidePanel('stable-diffusion');
            }}
            style={{
            width: '280px',
            height: '40px',
            fontSize: '15px',
            fontWeight: 'bold',
            alignItems: 'center',
            color: 'white',
            backgroundColor: '#8c9c7b',
            borderRadius: '30px' // Adicionando a propriedade borderRadius       
            
            }}
          />  
           <div style={{
            fontSize: '12,0px',
            fontWeight: 'normal',
            color: 'steelblue',
            textAlign: 'center'
            
          }}>
          Crie ilustrações com Inteligência Artificial e insira no seu projeto.
          </div> 
          </p>
          <br/>
         
          <p>
          <Button
            text="🎨 EDIÇÃO DE IMAGENS"
            intent="none"
             onClick={() => {
              store.openSidePanel('edicao-pro');    
            }}
            style={{
              width: '280px',
              height: '40px',
              fontSize: '15px',
              fontWeight: 'bold',
              alignItems: 'center',
              color: 'white',
              backgroundColor: '#8c9c7b',
              borderRadius: '30px' // Adicionando a propriedade borderRadius       
                    
            }}
          />  e
             <div style={{
            fontSize: '12,0px',
            fontWeight: 'normal',
            color: 'steelblue',
            textAlign: 'center'
            
          }}>
          Edite suas imagens de forma profissional com ferramentas IA.
          </div> 
          

          </p>
          </center>

     </div>
      </div>
    </div>
  );
});

// define the new custom section
export const IASection = {
  name: 'IA',
  Tab: (props) => (
    <SectionTab name="IA Criativa" {...props}>
      <FaBrain/>
    </SectionTab>
  ),
  visibleInList: false,

  // we need observer to update component automatically on any store changes
  Panel: IAPanel,
  
};