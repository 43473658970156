import React from 'react';
import { observer } from 'mobx-react-lite';
import EnOpenBook from '@meronex/icons/en/EnOpenBook';
import {
  Button,
  Navbar,
  Alignment,
  AnchorButton,
  Divider,
  Dialog,
  Classes,
  Position,
  Menu,
  MenuItem,
  MenuDivider,
  EditableText,
} from '@blueprintjs/core';
import { getImageSize } from 'polotno/utils/image';
import { Popover2 } from '@blueprintjs/popover2';
import { DownloadButton } from './download-button';
import { dataURLtoBlob, blobToDataURL } from '../blob';
import { downloadFile } from 'polotno/utils/download';

import styled from 'polotno/utils/styled';

const NavbarContainer = styled('div')`
  @media screen and (max-width: 500px) {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100vw;
  }
  &.bp4-navbar,
  & .bp4-navbar-group {
    height: 32px;
    background-color: #fbf9f9;
  }
  & .bp4-button {
    padding: 2px 10px;
  }
`;

const NavInner = styled('div')`
  @media screen and (max-width: 500px) {
    display: flex;
  }
`;


export default observer(({ store }) => {
  const [faqOpened, toggleFaq] = React.useState(false);

  const inputRef = React.useRef();

  const openFile = React.useRef(null);

  const abrirPopup = () => {
    window.open('https://fabricadoebook.com.br/loja-2/', 'Loja', 'width=1200px');
  }


  const addImage = async (file) => {
    const blob = await file.blob();
    blobToDataURL(blob, (data_url) => {
      getImageSize(data_url).then((size) => {
        store.setSize(size.width, size.height);
        store.activePage.addElement({
          type: 'image',
          src: data_url,
          width: size.width,
          height: size.height,
        });
      });
    });
  };

  return (
    <NavbarContainer className="bp4-navbar">
      <NavInner>
        <Navbar.Group align={Alignment.LEFT}>
          <Popover2
            content={
              <Menu>
                {/* <MenuDivider title={t('toolbar.layering')} /> */}


                
               {/* <MenuItem
                  icon="document"
                  text="Novo Projeto"
                  onClick={async () => {
                    const ids = store.pages
                      .map((page) => page.children.map((child) => child.id))
                      .flat();
                    const hasObjects = ids?.length;
                    if (hasObjects) {
                      if (
                        !window.confirm('Deseja abrir um novo projeto?')
                      ) {
                        return;
                      }
                    }
                    const pagesIds = store.pages.map((p) => p.id);
                    store.deletePages(pagesIds);
                    store.addPage();
                    window.project.id = '';
                    window.project.autosaveEnabled = true;
                    await window.project.save();
                  }}
                />

                <MenuDivider />
                */}


                {/*HABILITAR BOTÕES PARA BAIXAR E SUBIR JSON*/}


                
                <MenuItem
                  icon="floppy-disk"
                  text="Salvar Projeto (.json)"
                  onClick={() => {
                    const json = store.toJSON();
    
                    const url =
                      'data:text/json;base64,' +
                      window.btoa(
                        unescape(encodeURIComponent(JSON.stringify(json)))
                      );
    
                    downloadFile(url, 'fabrica-ebook.json');
                  }}
                />
            
            <MenuDivider />

          <MenuItem
                  icon="folder-open"
                  text="Abrir Projeto (.json)"
                  onClick={() => {
                    document.querySelector('#load-project').click();
                  }}
                />
 
              

      {/* Botão de salvar e abrir em nuvem no Puter*/}

           {/*     <MenuItem
                  icon="folder-open"
                  text="Abrir Projeto"
                  onClick={async () => {
                    const file = await window.puter.showOpenFilePicker();
                    window.project.openFile(file);
                    window.project.id = '';
                  }}
                />
                <MenuItem
                  icon="floppy-disk"
                  text="Salvar Projeto"
                  onClick={async () => {
                    const data = JSON.stringify(store.toJSON());
                    // If there is a file already open, overwrite it with the content of editor
                    const file = window.project.storeFile;
                    if (file) {
                      if (file.name.indexOf('.json') >= 0) {
                        file.write(data);
                      } else {
                        const dataURL = await store.toDataURL();
                        const blob = dataURLtoBlob(dataURL);
                        file.write(blob);
                      }
                    } else {
                      const name = 'itacaiunas.json';
                      window.project.storeFile =
                        await window.puter.showSaveFilePicker(data, name);
                      window.project.name = name;
                      window.autosaveEnabled = false;
                    }
                    window.project.status = 'saved';
                  }}
                />

           
              {/*  <MenuItem
                  icon={<FaFileImport />}
                  text="Exportar JSON"
                  onClick={async () => {
                    // const dataURL = await store.toDataURL();
                    // const blob = dataURLtoBlob(dataURL);
                    const data = JSON.stringify(store.toJSON());
                    const name = 'itacaiunas.json';
                    window.project.storeFile =
                      await window.puter.showSaveFilePicker(data, name);
                    window.project.name = name;
                    window.autosaveEnabled = false;
                    window.project.status = 'saved';
                  }}
                />

                */}

        <MenuDivider />

                <MenuItem
            text="Tools"
            minimal
            icon="box"         
            onClick={() => {
              store.openSidePanel('tools');            
            }}
          />  
              
          <MenuDivider />

         {/* <MenuItem
            text="📚 Tutoriais"
            minimal            
            onClick={() => {
              store.openSidePanel('tutoriais');            
            }}
          />  
          <MenuDivider /> */}

          <MenuItem
                  
                  text="Dúvidas❓"
                  minimal 
                  //icon="book"
                  onClick={() => {
                    toggleFaq(true);
                  }} />                   

                  <MenuDivider />   

            
              
            <MenuItem
            text="Nossa Loja 🛒"
              minimal                      
              onClick={abrirPopup}>                 
                             
              </MenuItem>

              <MenuDivider />   
                         
             

           {/* <MenuItem
            text="Sobre"
            minimal
            href="https://editoraitacaiunas.com.br/pequenos-autores/"
            target="_blank"
            icon={
              <BiCodeBlock className="bp4-icon" style={{ fontSize: '20px' }} />
            }         
            
          />*/}


              </Menu>
            }
            position={Position.BOTTOM_RIGHT}
            minimal
          >
            {/*<Button minimal text="Menu " />*/}
            <Button
          text="Menu +"
          intent="success"
          style={{
            width: '100px',
            height: '40px',
            fontSize: '16px',
            fontWeight: 'normal',
            color: 'green',
            backgroundColor: 'white'          
          }}
                        
          />
           

          </Popover2>

          <input
        type="file"
        id="load-project"
        accept=".json"
        ref={inputRef}
        style={{ width: '180px', display: 'none' }}
        onChange={(e) => {
          var input = e.target;

          if (!input.files.length) {
            return;
          }

          var reader = new FileReader();
          reader.onloadend = function () {
            var text = reader.result;
            let json;
            try {
              json = JSON.parse(text);
            } catch (e) {
              alert('Can not load the project.');
            }

            if (json) {
              store.loadJSON(json);
              input.value = '';
            }
          };
          reader.onerror = function () {
            alert('Can not load the project.');
          };
          reader.readAsText(input.files[0]);
        }}
      />

          {/* <Button
            icon="floppy-disk"
            minimal
            onClick={async () => {
              const dataURL = await store.toDataURL();
              const blob = dataURLtoBlob(dataURL);
              openFile.current = await cloud.showSaveFilePicker(
                blob,
                'polotno.png'
              );
            }}
          >
            Save As
          </Button> */}
      <MenuDivider />

    {/* 
          <Button
            text="Meus E-books"
            intent="success"
            style={{
              width: '130px',
              height: '40px',
              fontSize: '15px',
              fontWeight: 'normal',
              color: 'green',
              backgroundColor: 'white'          
            }}
            
            onClick={() => {
              store.openSidePanel('my-designs');
            }}
          />
          <div
            style={{
              paddingLeft: '50px',
              maxWidth: '200px',
            }}
          >
            <EditableText
              value={window.project.name}
              placeholder="Nome do projeto"
              onChange={(name) => {
                window.project.name = name;
                window.project.requestSave();
              }}
            />
          </div>
*/}
          

           <MenuDivider />

        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          {/* <a
          href="https://www.producthunt.com/posts/polotno-studio?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-polotno-studio"
          target="_blank"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=281373&theme=dark"
            alt="Polotno Studio - Canva-like design editor, without signups or ads. | Product Hunt"
            style={{ height: '30px', marginBottom: '-4px' }}
          />
        </a> */}
          {/* <Button
            icon="info-sign"
            minimal
            onClick={() => toggleQuestion(true)}
            intent="danger"
          >
            Important question for you (!)
          </Button> */}


                 {/* <div className='botao-topo'>
                  <Button
                  
                  //text="📖 PUBLIQUE SEU E-BOOK GRATUITAMENTE"
                 // intent="success"                  
                  //icon="book"
                  
                  onClick={() => {
                    toggleFaq(true);
                  }}
                  >                    
                   ✨TUTORIAL!
                    </Button>     
                    </div>   */}            

                  <MenuDivider />         
         
         {/*HABILITAR BOTÕES PARA BAIXAR E SUBIR JSON*/}
          
          {/*<Button
                icon="floppy-disk"
               // text="Save as"
                onClick={() => {
                  const json = store.toJSON();
  
                  const url =
                    'data:text/json;base64,' +
                    window.btoa(
                      unescape(encodeURIComponent(JSON.stringify(json)))
                    );
  
                  downloadFile(url, 'fabrica.json');
                }}
                        >
            Salvar Local
          </Button>


          <MenuDivider />

             <Button
              // icon={<FaFileImport />}
              icon="folder-open"
             // text="Open"
              onClick={() => {
                document.querySelector('#load-project').click();
              }}
            >
               Abrir Projeto
          </Button>    
                  */} 


              <AnchorButton
              intent="none" 
              style={{
                width: '70%',
                height: '50px',
                alignContent: 'center',
                tableLayout:'fixed', }}              
              onClick={abrirPopup}>
              🛒   
                             
              </AnchorButton>

              <MenuDivider />  

              <AnchorButton
              intent="none"
              style={{
                width: '40%',
                height: '50px',
                alignContent: 'center',
                tableLayout:'fixed', }}              
              onClick={() => {
                toggleFaq(true);
              }}>
              ❓   
                             
                </AnchorButton>                  

                  <MenuDivider />    

          <DownloadButton store={store}/> 

          
          <MenuDivider />   
           
          
         {/*  <AnchorButton
            minimal
            href="https://github.com/lavrton/polotno-studio"
            target="_blank"
            icon={
              <FaGithub className="bp4-icon" style={{ fontSize: '20px' }} />
            }
          >
            Github
          </AnchorButton>

          <AnchorButton
            minimal
            href="https://discord.gg/W2VeKgsr9J"
            target="_blank"
            icon={
              <FaDiscord className="bp4-icon" style={{ fontSize: '20px' }} />
            }
          >
            Juste
          </AnchorButton>*/}
          
          
        
        </Navbar.Group>
        <Dialog
          icon="info-sign"
          onClose={() => toggleFaq(false)}
          title="Dicas de uso da plataforma"
          isOpen={faqOpened}
          style={{
            width: '80%',
            maxWidth: '700px',
            
          }}
        >
          <div className={Classes.DIALOG_BODY}>

        
         {/* <center><img src="" width="150" height=""></img>*/} 
            <center>
         
                                           
            <table  style={{
            width: '90%',
            alignContent: 'center',
            tableLayout:'fixed', }}>
            <tbody>

            

            <tr>
              <td  style={{
              width: '280px',
              height: '40px',
              fontSize: '16px',
             //fontWeight: 'bold',
              alignItems: 'center',
              color: 'green',
              paddingBottom:'5px',
              fontFamily: 'sans-serif',
              alignContent: 'center',
              textAlign: 'justify'
              //backgroundColor: '#ffffff',
              //borderRadius: '30px' // Adicionando a propriedade borderRadius  

            }}> 
            <p>Utilze os botões interativos da plataforma para executar ações como duplicar, inserir, excluir páginas. 
              O editor online é bastante intuitivo e é muito semelhante a outros editores de texto e desing gráfico
              como Word e até editores online como o Canva. Incentivamos que use essa plataforma em ambiente de desktop, 
              pois apesar dele funcionar perfeitamente em celulares e tabletes, o uso no computador é mais rápido e fácil
              para qualquer trabalho que envolva edição de texto e imagens.</p> 
              <b>⌨️ Teclas de atalhos úteis:</b><br/><br/>
              Ctrl + C - copiar conteúdo e elementos das páginas<br/>
              Ctrl + V - colar conteúdo e elementos nas páginas<br/>
              Ctrl + B - Deixar o texto selecionado em negrito<br/>
              Ctrl + I: Deixar o texto selecionado em itálico.<br/>
              Ctrl + U: Sublinhar o texto selecionado.<br/>
              Ctrl + Z: Desfazer uma ação.<br/>
              Ctrl + Y: Resfazer uma ação.<br/>
              Ctrl + A: Selecionar todo o texto.
              <br/><br/>
              
              <b> 🟢 Mais para o seu e-book:</b><br/><br/>

            Agora que seu e-book já está finalizado, você pode publicá-lo com o selo editorial Palafita Book e ainda ganhar
            dinheiro comercializado através da nossa plataforma Publicar Livros.
       
            <br/>
           
            
            </td>

            </tr>         
               
                    
            </tbody>
            </table>

              <br/>
            </center>

            <center>
              
              <AnchorButton
              intent="success" 
              style={{
                width: '70%',
                height: '50px',
                alignContent: 'center',
                tableLayout:'fixed', }}              
              onClick={abrirPopup}>
              SAIBA MAIS!       
                             
                </AnchorButton>
              </center> 

              <br/><br/>

             
                            
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => toggleFaq(false)}>(X) Fechar </Button>
            </div>
          </div>
        </Dialog>
      </NavInner>
    </NavbarContainer>
  );
});
