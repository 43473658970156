import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, AnchorButton } from '@blueprintjs/core';
import { ImagesGrid } from 'polotno/side-panel';
import { getImageSize } from 'polotno/utils/image';
import FaCloudUploadAlt from '@meronex/icons/fa/FaCloudUploadAlt';
import { getImages, saveImage, deleteImage } from './api';

export const UploadPanel = observer(({ store }) => {
  const [images, setImages] = React.useState([]);
  const [isUploading, setUploading] = React.useState(false);

  const load = async () => {
    const images = await getImages();
    setImages(images);
  };

  const handleFileInput = async (e) => {
    const { target } = e;
    setUploading(true);
    for (const file of target.files) {
      await saveImage(file);
    }
    await load();
    setUploading(false);
    target.value = null;
  };

  React.useEffect(() => {
    load();
  }, []);

  return (

    
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ marginBottom: '20px' }}>
       
      <center>
        <h5 style={{ marginBottom: '10px', marginTop: '5px' }}>
        <FaCloudUploadAlt/> Upload é um recurso Plus💠 </h5>        
        
       <p style={{fontSize: '13.6px', fontWeight: '500px', color: 'black', marginTop: '20px' }}>
        Deseja carregar suas imagens? Assine o Plus para você adicionar todos os arquivos de imagem que quiser ao seu design.</p>
      </center>


       <center>          
          <AnchorButton
            intent="success"
            href="https://fabricadoebook.com.br/assinar-plano/"
            target="_blank"
           //</div> icon={
             // <FcPlus className="bp4-icon" style={{ fontSize: '20px' }} />
            //}          
          style={{
            width: '180px',
            height: '20px',
            fontSize: '15px',
            fontWeight: 'bold',
            alignItems: 'center',
            marginTop: '15px',
            color: 'white',
            backgroundColor: '#8c9c7b',
            borderRadius: '30px' // Adicionando a propriedade borderRadius
       
          }}
          >
            Desbloquear tudo
          </AnchorButton>

          </center>


        {/*
         <label htmlFor="input-file">         
          <Button
           // icon="upload"
            style={{ width: '100%' }}
            onClick={() => {
              document.querySelector('#input-file')?.click();
            }}
            loading={isUploading}
          >
           <FaCloudUploadAlt/> ENVIAR IMAGENS
          </Button>

          <input
            type="file"
            id="input-file"
            style={{ display: 'none' }}
            onChange={handleFileInput}
            multiple
          />
        </label> 
        */}

      </div>

      <div className='scrollable-container-capa'>     


{/*
      <ImagesGrid
        images={images}
        getPreview={(image) => image.url}
        crossOrigin="anonymous"
        getCredit={(image) => (

          <div>
            <Button
              icon="trash"
              onClick={async (e) => {
                e.stopPropagation();
                if (
                  window.confirm('Tem certeza de que deseja excluir a imagem?')
                ) {
                  await deleteImage(image.id);
                  await load();
                }
              }}
            >
            </Button>
            
          </div>

          
        )}
        onSelect={async (image, pos, element) => {
          const { url } = image;
          let { width, height } = await getImageSize(url);
          const isSVG = url.indexOf('svg+xml') >= 0 || url.indexOf('.svg') >= 0;

          const type = isSVG ? 'svg' : 'image';

          if (
            element &&
            element.type === 'svg' &&
            !element.locked &&
            type === 'image'
          ) {
            element.set({ maskSrc: url });
            return;
          }

          if (
            element &&
            element.type === 'image' &&
            !element.locked &&
            type === 'image'
          ) {
            element.set({ src: url });
            return;
          }

          const scale = Math.min(store.width / width, store.height / height, 1);
          width = width * scale;
          height = height * scale;

          const x = (pos?.x || store.width / 2) - width / 2;
          const y = (pos?.y || store.height / 2) - height / 2;

          store.activePage?.addElement({
            type,
            src: url,
            x,
            y,
            width,
            height,
          });
        }}
      />
      
      */}

    </div>
    </div>
    
  );
});
