import React from 'react';
import '../index.css';
import { observer } from 'mobx-react-lite';
import { SectionTab} from 'polotno/side-panel';
import FaVideo from "@meronex/icons/fa/FaVideo";
import { unstable_registerShapeModel } from "polotno/config";
import {
  Button,
  Dialog,
  Classes,
  Position,
  Menu,
  MenuItem,
  MenuDivider,
  Divider,
  InputGroup,
  Tab, 
  Tabs,
} from '@blueprintjs/core';

export const TutorialCustom = observer(({ store }) => {
    unstable_registerShapeModel({
        type: "video",
        source: "user",
        width: 100,
        height: 100,
        quality: "good",
        x:100,
        y:100,
    });
    return (

        <div>  
                  

    <div className='scrollable-container-videos'>
      
      
        <div>   
          <h2>Tutoriais</h2>
          <h3>(▶️) Vídeos tutoriais</h3>   
          <div style={{
            fontSize: '12,5px',
            fontWeight: 'normal',
            color: 'steelblue',
            textAlign: 'left'
            
          }}>
          Conheça nosso canal no Youtube com conteúdos gravados em vídeos mostrando os usos da IA para escritores.
          </div> 
          <br/>

          <Button                 
                  text="🔗ACESSAR CANAL!"
                  onClick={() => window.open('https://www.youtube.com/playlist?list=PLHgi47vGCVqBkklBwDumxvY--6y0Z7f4n', '_blank')}              
               
                style={{
                  width: '100%',
                  height: '32px',
                  fontSize: '13px',
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: '#8c9c7b'
                }}
            />            
               
        </div>
   
        <MenuDivider />
        <div>

        <h3> 💻 Blog Tutoriais IA</h3>      

            <div style={{
            fontSize: '12,5px',
            fontWeight: 'normal',
            color: 'steelblue',
            textAlign: 'left'
            
          }}>
          Visite nosso conteúdo sobre Inteligência Artificial e saiba mais.
          </div> 
          <br/>

        <Button                 
                  text="🔗 VISITAR BLOG!"
                  onClick={() => window.open('https://editoraitacaiunas.com.br/tag/tutoriais-ia/', '_blank')}    
                  style={{
                    width: '100%',
                    height: '32px',
                    fontSize: '13px',
                    fontWeight: 'bold',
                    color: 'white',
                    backgroundColor: '#8c9c7b'
                  }}   
                />
               
                <MenuDivider />
         
        </div> 

        <div>               
          <h3>📚 Livraria (Impresso e e-books) </h3>

          <div style={{
            fontSize: '12,5px',
            fontWeight: 'normal',
            color: 'steelblue',
            textAlign: 'left'
            
          }}>
          Confira nossos títulos disponíveis sobre IA aplicadas a redação, ilustração, divulgação e publicação de livros
          </div> 
          <br/>         
         
               <Button                 
                  text="🔗 LIVRARIA IA"
                  onClick={() => window.open('https://editoraitacaiunas.com.br/produto-tag/inteligencia-artificial-ia/', '_blank')}
                  style={{
                    width: '100%',
                    height: '32px',
                    fontSize: '13px',
                    fontWeight: 'bold',
                    color: 'white',
                    backgroundColor: '#8c9c7b'
                  }}             
                />      
      
        </div> 
         </div>
        </div>
        
        
    )
    
})


// define the new custom section
export const TutorialSection = {
  name: 'tutoriais',
  Tab: (props) => (
    <SectionTab name="Tutoriais" {...props}>
      <FaVideo />
    </SectionTab>
  ),
  visibleInList: false,
  // we need observer to update component automatically on any store changes
  Panel: TutorialCustom,
  
};