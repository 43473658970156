import React from 'react';
import '../index.css';
import { observer } from 'mobx-react-lite';
import { SectionTab} from 'polotno/side-panel';
import FaVideo from "@meronex/icons/fa/FaVideo";
import {
  Button,
  Dialog,
  Classes,
  Position,
  Menu,
  MenuItem,
  MenuDivider,
  Divider,
  InputGroup,
  Tab, 
  Tabs,
} from '@blueprintjs/core';

export const CatalogacaoCustom = observer(({ store }) => {
   
    return (

        <div>  
          <Button
            text="Voltar"
            //intent="none"
            icon="arrow-left"
            onClick={() => {
              store.openSidePanel('comece-livro');            
            }}
          />      
    <h3>Gerador de Cutter-Sanborn</h3>
    {/*<div className='texto-tab-anuncio'>
<p>Espaço reservado para anuncios mais recusrsos.</p>
        </div>*/}

    <div className='scrollable-container-miolo-modelo'>
                     
          <p></p>   
          <iframe src="https://itacaiunas.com.br/gerador-online-de-cutter-sanborn/" width="100%" height="350"></iframe>
          <br/>
          <br/><br/>

              </div> 
                </div>
    )
})

// define the new custom section
export const CatalogacaoSection = {
  name: 'catalogar',
  Tab: (props) => (
    <SectionTab name="Toolbox" {...props}>
      <FaVideo />
    </SectionTab>
  ),
  visibleInList: false,
  // we need observer to update component automatically on any store changes
  Panel: CatalogacaoCustom,
  
};