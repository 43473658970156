import React from 'react';
import BisBookAlt from '@meronex/icons/bi/BisBookAlt';
import '../index.css';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { SectionTab } from 'polotno/side-panel';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import TiDeviceTablet from '@meronex/icons/ti/TiDeviceTablet';

import {  
  AnchorButton,
} from '@blueprintjs/core';

export const TemplatesCapas = observer(({ store }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // load data1
  const { data: data1, isLoading: isLoading1 } = useInfiniteAPI({
    getAPI: ({ page }) => `templates-capa/page${page}.json`,
  });

  // load data2
  const { data: data2, isLoading: isLoading2 } = useInfiniteAPI({
    getAPI: ({ page }) => `templates-capa-free/page${page}.json`,
  });

  function filterData(data, searchTerm) {
    if (!searchTerm) {
      return data;
    }
    return data.filter(
      (item) =>
        item.json.includes(searchTerm) || item.preview.includes(searchTerm)
    );
  }

  return (       
    <div>
     <div className='templates-section'>
    <center>       
      <img src="https://publicarlivros.com.br/wp-content/uploads/2023/07/logo-fabrica-do-ebook.png" width="120px"></img>
    </center>
      <div style={{
        fontSize: '17,5px', 
        fontWeight: 'normal',
        color: 'green',
        textAlign: 'center'

      }}>
        Crie seu e-book a partir dos nossos modelos!
      <br/>
      
      <hr/>
      </div>
       
        <div className='scrollable-container-capa'>
          <ImagesGrid
            shadowEnabled={false}
            images={filterData(
              data1?.map((data) => data.items).flat(),
              searchTerm
            )}
            getPreview={(item) => `./templates-capa/${item.preview}`}
            isLoading={isLoading1}
            onSelect={async (item) => {
              // download selected json
              const req = await fetch(`./templates-capa/${item.json}`);
              const json = await req.json();
              // just inject it into store
              store.loadJSON(json);
            }}
            // numero de colunas da tab MODELOS
            rowsNumber={3}
          />
           <br/>       
        <div style={{
        fontSize: '18,5px', 
        fontWeight: 'bold',
        color: 'steelblue',
        textAlign: 'center'
        
      }}>
        Mais Capas Assine Plus💠!
      <br/>
        </div>
        <center>
          <p>Tenha acesso a divermos modelos de capas e diferencie seu e-book</p>  
          <AnchorButton
            intent="success"
            href="https://fabricadoebook.com.br/assinar-plano/"
            target="_blank"
           //</div> icon={
             // <FcPlus className="bp4-icon" style={{ fontSize: '20px' }} />
            //}          
          style={{
            width: '180px',
            height: '20px',
            fontSize: '15px',
            fontWeight: 'bold',
            alignItems: 'center',
            color: 'white',
            backgroundColor: '#8c9c7b',
            borderRadius: '30px' // Adicionando a propriedade borderRadius
       
          }}
          >
            Desbloquear tudo
          </AnchorButton>

          </center>

        <br/><br/>
         
        <img src="./assets/capas-pro.png" width="300px" ></img>  
        <br/><br/>
       <center>
        <b>         
          <p>Todos os meses são adicionados mais modelos de capas na versão Plus da Fábrica do Livro.</p>
          </b>   
          <AnchorButton
            intent="success"
            href="https://fabricadoebook.com.br/assinar-plano/"
            target="_blank"
           //</div> icon={
             // <FcPlus className="bp4-icon" style={{ fontSize: '20px' }} />
            //}          
          style={{
            width: '180px',
            height: '20px',
            fontSize: '15px',
            fontWeight: 'bold',
            alignItems: 'center',
            color: 'white',
            backgroundColor: '#8c9c7b',
            borderRadius: '30px' // Adicionando a propriedade borderRadius
       
          }}
          >
            Saiba mais!
          </AnchorButton>
          </center>
        </div>
      </div>

      <div className='templates-section'>
       
        <div className='scrollable-container-capa'>

    {/*<Button
            text="Acessar"
            minimal
             onClick={() => {
              store.openSidePanel('modelos-capas');            
            }}
            style={{
              width: '300px',
              height: '40px',
              fontSize: '15px',
              fontWeight: 'bold',
              color: 'black',
              backgroundColor: '##daf2fa'       
            }}
            /> */} 

        </div>
      </div>
    </div>
  );
});


// define the new custom section
export const CapaSection = {
  name: 'capa',
  Tab: (props) => (
    <SectionTab name='Modelos' {...props}>
      <TiDeviceTablet />
    </SectionTab>
  ),
  visibleInList: true,

  // we need observer to update component automatically on any store changes
  Panel: TemplatesCapas,
};
