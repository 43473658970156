import React from 'react';
import '../index.css';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { SectionTab } from 'polotno/side-panel';
import { DownloadButton } from './download-button';
import AiFillDashboard from '@meronex/icons/ai/AiFillDashboard';


import {
  Button,
 
} from '@blueprintjs/core';

function handleClick(event) {
  const buttonId = event.target.id;
  
  // faça algo com o ID do botão clicado
  console.log('Botão clicado: ', buttonId);
}

export const ComecarLivros = observer(({ store }) => {
  const [searchTerm, setSearchTerm] = useState('');

   return (
    <div>            
      <div className='templates-section'>
      <div style={{
        fontSize: '20px',
        fontStyle: 'initial',
        fontWeight: 'bold',
        color: 'green',
        textAlign: 'left'
      }}>
        Painel
      </div>
       <hr/>
        
        <div className='scrollable-container-miolo'>

        <div style={{
        fontSize: '13,5px', 
        fontWeight: 'normal',
        color: 'steelblue',
        textAlign: 'left'

      }}>
        Crie seu e-book a partir dos nossos modelos!
      <br/>
      <br/>
      </div>
        
       <left>
       <Button
            text="Modelos Layouts"
            intent="primary"
            onClick={() => {
            store.openSidePanel('miolo'); 
            }}
            style={{
              width: '240px',
              height: '40px',
              fontSize: '20px',
              fontWeight: 'normal',
              color: 'white',
              backgroundColor: '#8c9c7b',
              borderRadius: '30px' // Adicionando a propriedade borderRadius                              
            }}
          />  
         
          <br/><br/>

          <Button
            text="Modelos de Capas"
            intent="primary"
             onClick={() => {
              store.openSidePanel('capa');   
            }}
            style={{
              width: '240px',
              height: '40px',
              fontSize: '20px',
              fontWeight: 'normal',
              color: 'white',
              backgroundColor: '#8c9c7b',
              borderRadius: '30px' // Adicionando a propriedade borderRadius
         
            }}
          />  
         
         <br/><br/>
                           
     {/*   <Button
            text="Gerar Cutter"
            intent="primary"
            icon="circle"
            onClick={() => {
              store.openSidePanel('catalogar');
            }}
            style={{
              width: '200px',
              height: '40px',
              fontSize: '20px',
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: '#8c9c7b',
              borderRadius: '30px' // Adicionando a propriedade borderRadius
            
            }}
            
          />  
          <div style={{
        fontSize: '12,0px',
        fontWeight: 'normal',
        color: 'steelblue',
        textAlign: 'left'
        
      }}>
        Gere o código Cutter-Sanborn.
    </div> */}
         <br/>              
    </left>

      <center>
      <div style={{
            fontSize: '13,5px',
            fontWeight: 'normal',
            color: 'steelblue',
            textAlign: 'center'            
          }}>
          Após finalizar seu projeto é possível baixar seus resultados nos formatos PDF e PNG.
          </div> 
        <h3><DownloadButton store={store} />  {/*------Botão Baixar da topbar */}
      </h3>
      </center>

          {/*  
          <h3>Versão 1.0 - Todos direitos reservados a Editora Itacaiúnas. Gostaria de mais recursos no ELO? Conheça as vantagens da versão PLUS.</h4>
           <AnchorButton
            intent="none"
            href="https://editoraitacaiunas.com.br/editor-de-livros-online-itacaiunas/"
            target="_blank"
            >
            ASSINAR ELO PLUS💠
          </AnchorButton>
          */}      

     </div>
      </div>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      <center>

      <footer>
      <div style={{
            fontSize: '13,5px',
            fontWeight: 'normal',
            color: 'steelblue',
            textAlign: 'left'            
          }}>
          Editor de Livro Online - ELO (Versão 1.1 )
          </div> 
        <h4></h4></footer></center>
    </div>   

  );
});

// define the new custom section
export const ComecarLivroSection = {
  name: 'comece-livro',
  Tab: (props) => (
    <SectionTab name="Painel" {...props}>
      <AiFillDashboard/>
    </SectionTab>
  ),
  visibleInList: true,

  // we need observer to update component automatically on any store changes
  Panel: ComecarLivros,
  
};