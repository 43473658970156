import React from 'react';
import { observer } from 'mobx-react-lite';
import { SectionTab } from 'polotno/side-panel';
import QRCode from 'qrcode';
import * as svg from 'polotno/utils/svg';
import FaQrcode from '@meronex/icons/fa/FaQrcode';
import { Button, AnchorButton, InputGroup } from '@blueprintjs/core';



// create svg image for QR code for input text
export async function getQR(text) {
  return new Promise((resolve) => {
    QRCode.toString(
      text || 'no-data',
      {
        type: 'svg',
        color: {
          dark: '#000', // Blue dots
          light: '#fff', // Transparent background
        },
      },
      (err, string) => {
        resolve(svg.svgToURL(string));
      }
    );
  });
}

// define the new custom section
export const QrSection = {
  name: 'qr',
  Tab: (props) => (
    <SectionTab name="QR code" {...props}>
      <FaQrcode />
    </SectionTab>
  ),  
  visibleInList: false,

  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }) => {
    const inputRef = React.useRef();
    return (     
      
      <div>


<Button
            text="Voltar"
            //intent="none"
            icon="arrow-left"
            onClick={() => {
              store.openSidePanel('tools');            
            }}
            style={{
              width: '180px',
              height: '20px',
              fontSize: '15px',
              fontWeight: 'bold',
              alignItems: 'center',
              color: 'black',
              backgroundColor: '#ffffff',
              borderRadius: '30px' // Adicionando a propriedade borderRadius
         
            }}
            ></Button>

   <center> <h5 style={{ marginBottom: '10px', marginTop: '5px' }}>
          <FaQrcode /> QR Code é um recurso Plus💠</h5> 
        
        <p style={{fontSize: '13.6px', fontWeight: '500px', color: 'black', marginTop: '20px' }}>
        Deseja criar QR Codes ilimitados para o seu e-book? Assine o Plus para você adicionar ilimitados QR Codes ao seu design.</p>
        </center>  
        
           <center>           
          <AnchorButton
            intent="success"
            href="https://fabricadoebook.com.br/assinar-plano/"
            target="_blank"
           //</div> icon={
             // <FcPlus className="bp4-icon" style={{ fontSize: '20px' }} />
            //}          
          style={{
            width: '180px',
            height: '20px',
            fontSize: '15px',
            fontWeight: 'bold',
            alignItems: 'center',
            marginTop: '15px',
            color: 'white',
            backgroundColor: '#8c9c7b',
            borderRadius: '30px' // Adicionando a propriedade borderRadius
       
          }}
          >
            Desbloquear tudo
          </AnchorButton>

          </center>
        
{/*
        <InputGroup
          placeholder="Cole ou digite aqui"
          style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
          inputRef={inputRef}
        />      

        <Button
          onClick={async () => {
            const src = await getQR(inputRef.current.value);

            store.activePage.addElement({
              type: 'svg',
              name: 'qr',
              x: 50,
              y: 50,
              width: 200,
              height: 200,
              src,
            });
          }}
          fill
          intent="primary"
        >
          Adicionar QRCode!
        </Button>
        */}
      </div>
        
    );
  }),
};
